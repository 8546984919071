<template>
  <div>
    <div class="form-inline sub-mr-2">
      <div>Set Local Asset Folder:</div>
      <b-form-input class="flex-grow-1" v-model="assetsPath" placeholder="Asset folder on this computer" />
      <button class="btn btn-primary" @click="saveAssetPath" :disabled="!isValidPath(assetsPath)">Save</button>
    </div>
    <h2>Asset Library</h2>
    <div v-if="opsOn" class="form-inline sub-mr-2 mb-2">
      <div>Ops:</div>
      <button class="btn btn-primary" @click="setAssetDate()">Set Date</button>
      <button class="btn btn-primary" @click="setAssetsPath()">Set Paths</button>
    </div>
    <b-tabs v-model="tabIndex" fill>
      <b-tab title="All" active/>
      <b-tab title="Animations"/>
      <b-tab title="Characters"/>
      <b-tab title="Environments"/>
      <b-tab title="Props"/>
      <b-tab title="Materials"/>
      <b-tab title="Sounds"/>
      <b-tab title="VFX"/>
    </b-tabs>
    <b-table striped hover :items="filteredAssets" :fields="assetsInfoFields" sort-by="name"
      @row-clicked="editAssetInfo">
      <template v-slot:cell(name)="data">
        <b>{{ data.item.storeInfo?.title }}</b>&nbsp;
        <b-button class="btn btn-secondary" @click="showAsset(data.item)"
          v-if="data.item.storeInfo" size="sm">Details</b-button><br />
        Path: {{ data.item.path }}
        <b-badge v-if="data.item.wip">WIP</b-badge>&nbsp;
        <b-badge v-if="data.item.custom">Custom</b-badge>&nbsp;
        <b-badge v-if="data.item.version">V{{ data.item.version }}</b-badge>
        <div v-if="data.item.storeInfo" class="half-width">
          <div class="overflow-auto">
            <div class="d-flex">
              <div v-for="(imageUrl, index) in cleanImagesUrls(data.item.storeInfo.imageUrls)"
                :key="data.item.key + '_' + index">
                <img :src="imageUrl" style="max-width: 300px;" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:cell(edit)="data">
        <div v-if="isEdit?.key == data.item.key" class="sub-mt-2 sub-mr-2">
          <b-form-input v-model="url" placeholder="Marketplace Url"></b-form-input>
          <b-form-input v-model="videoUrl" placeholder="Video Url"></b-form-input>
          <b-form-input v-model="demoUrl" placeholder="Demo Url"></b-form-input>
          <b-form-input v-model="size" placeholder="Size"></b-form-input>
          <!-- checkbox for wip and custom -->
          <b-form-checkbox v-model="wip">WIP</b-form-checkbox>
          <b-form-checkbox v-model="custom">Custom</b-form-checkbox>
          <button class="btn btn-primary"
            @click="saveAssetInfo(data.item.key, { path:data.item.path, url, videoUrl, demoUrl, wip, custom, size })">Save</button>
          <button class="btn btn-secondary" @click="isEdit = null">Cancel</button>
          <!-- calculate size asset -->
          <!--
          <button class="btn btn-secondary" @click="calculateSize(data)">
            Compute Size
          </button>
          -->
        </div>
      </template>
      <template v-slot:cell(size)="data">
        {{ data.item.size ? humanFileSize(data.item.size) : "N/A" }}
      </template>
      <template v-slot:cell(date)="data">
        {{ data.item.date?.toDate() | formatDate("YYYY-MM-DD") }}
      </template>
      <template v-slot:cell(actions)="data">
        <div class="sub-mr-2 sub-mt-2">
          <!-- marketplace url -->
          <button class="btn btn-primary" v-if="data.item.url"
            @click="openExternal(data.item.url)" tooltip="See on Unreal Marketplace">
            <font-awesome-icon icon="link" />
          </button>
          <!-- video url -->
          <button class="btn btn-primary" v-if="data.item.videoUrl"
            @click="openExternal(data.item.videoUrl)" tooltip="See video">
            <font-awesome-icon icon="circle-play" />
          </button>
          <!-- demo url -->
          <button class="btn btn-primary" v-if="data.item.demoUrl"
            @click="openExternal(data.item.demoUrl)" tooltip="See demo">
            <font-awesome-icon icon="cog" />
          </button>
          <!-- get asset -->
          <b-button @click="getAsset(data.item)" :disabled="!isValidPath(assetsPath)">Get</b-button>
          <!-- already installed -->
          <font-awesome-icon icon="check-circle" v-if="assetsFoldersExists[data.item.key]" />
        </div>
      </template>
    </b-table>
    <b-modal id="store-info-modal" v-if="assetInfo" centered ok-only :title="assetInfo?.storeInfo?.title" size="lg">
      <div class="overflow-auto">
        <div class="d-flex">
          <div v-for="(imageUrl, index) in cleanImagesUrls(assetInfo.storeInfo.imageUrls)" :key="index">
            <img :src="imageUrl" style="max-width: 300px;" />
          </div>
        </div>
      </div>
      <div v-if="assetInfo.storeInfo.description">
        <h2>Description</h2>
        <span v-html="assetInfo.storeInfo.description"></span>
      </div>
      <div v-if="assetInfo.storeInfo.technicalDetails">
        <h2>Technical Details</h2>
        <span v-html="assetInfo.storeInfo.technicalDetails"></span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { getLog } from '@/services/log';
let log = getLog('sget-assets', true);
import { db, storage, serverTimestamp } from '@/services/db';
import { removeUndefined, bindMap } from "@/services/utils";
import { calculateFolderSize, humanFileSize } from "@/services/dbutils";
import { checkFolderExists, isValidPath, openExternal } from './shivarun';

export default {
  data() {
    return {
      assetsInfo_asArray: [],
      assetsPath: '',
      assetsOnStorage: [],
      assetsInfo: {},
      assetsFoldersExists: {},
      assetInfo: null,
      useAssetsFromDb: true,

      isEdit: null,

      url: '',
      videoUrl: '',
      demoUrl: '',
      wip: false,
      custom: false,
      size: 0,

      tabNames: [
        '',
        'Animations',
        'Characters',
        'Environments',
        'Props',
        'Materials',
        'Sounds',
        'VFX',
      ],
      tabIndex: 0,
      config: {},
    };
  },
  computed: {
    assetsInfoFields() {
      let fields = [
        { key: 'name', label: 'Name' },
        { key: 'size', label: 'Size' },
        { key: 'date', label: 'Date' },
        { key: 'actions', label: 'Actions' },
      ];
      if (this.isEdit) {
        fields.splice(3, 0, { key: 'edit', label: 'Edit' });
      }
      return fields;
    },
    opsOn() {
      return this.$debug.isOn;
    },
    filteredAssets() {
      return this.useAssetsFromDb ? this.filteredAssetsFromDb : this.filteredAssetsMergeGCSandDb;
    },
    filteredAssetsMergeGCSandDb() {
      let filter = this.tabIndex ? "/" + this.tabNames[this.tabIndex].toLowerCase() + "/" : "";
      return this.assetsOnStorage.filter((item) => {
        return item.name.toLowerCase().includes(filter);
      }).map((item) => {
        return {
          ...item,
          ...this.assetsInfo[item.key],
        } 
      });
    },
    filteredAssetsFromDb() {
      let filter = this.tabIndex ? "_" + this.tabNames[this.tabIndex].toLowerCase() + "_" : "";
      return this.assetsInfo_asArray.filter((item) => {
        return item.id.toLowerCase().includes(filter);
      }).map((item) => {
        return {
          ...item,
          key: item.id,
        } 
      });
    }
  },
  mounted() {
    log.log('mounted');
    this.init();
  },
  methods: {
    isValidPath,
    openExternal,
    humanFileSize,
    cleanImagesUrls(imageUrls) {
      if (imageUrls && imageUrls[0] && !imageUrls[0].includes('epicgames.com')) {
        return imageUrls;
      }
      // remove all query parameters from the image urls and remove duplicates
      return imageUrls.map((url) => url.split('?')[0]).filter((value, index, self) => self.indexOf(value) === index);
    },
    showAsset(assetInfo) {
      log.log('showAsset', assetInfo);
      this.assetInfo = assetInfo;
      this.$nextTick(() => {
        this.$bvModal.show('store-info-modal');
      })
    },
    async init() {
      log.log('init');
      // load assetsPath from ShivaGetUserConfigs
      db.collection('ShivaGetUserConfigs').doc(this.$store.account.uid).get().then((doc) => {
        if (doc.exists) {
          this.assetsPath = doc.data().assetsPath;
        }
      });
      await this.$bind("config", db.collection('ShivaGetUserConfigs').doc('00Assets'));
      if (!this.useAssetsFromDb)
        this.loadAssetsFromGCS();
      await bindMap(this, "assetsInfo", db.collection('ShivaGetAssets'));
      if (this.useAssetsFromDb)
        this.scanLocalAssets();
    },
    saveAssetPath() {
      log.log('saveAssetPath', this.assetsPath);
      db.collection('ShivaGetUserConfigs').doc(this.$store.account.uid).set({
        assetsPath: this.assetsPath,
      }, { merge: true });
    },
    editAssetInfo(item) {
      log.log('editAssetInfo', item.name);
      if (!this.opsOn)
        return;
      if (this.isEdit?.key == item.key) {
        return;
      }
      this.isEdit = item;
      this.url = this.assetsInfo[item.key]?.url;
      this.videoUrl = this.assetsInfo[item.key]?.videoUrl;
      this.demoUrl = this.assetsInfo[item.key]?.demoUrl;
      this.wip = this.assetsInfo[item.key]?.wip;
      this.custom = this.assetsInfo[item.key]?.custom;
      this.size = this.assetsInfo[item.key]?.size;
    },
    saveAssetInfo(key, values) {
      log.log('saveAssetInfo', key);
      db.collection('ShivaGetAssets').doc(key).set(
        removeUndefined(values),
        { merge: true }
      );
      this.isEdit = null;
    },
    getAsset(asset) {
      this.getAssetFromB2(asset);
    },
    getAssetGCS(asset) {
      log.log('getAsset', asset.path);
      let token = this.config.gcsToken;
      this.$emit('run-command', 'ShivaGetAsset.bat',
        [asset.path, this.assetsPath],
        {
          env: { "RCLONE_GCS_TOKEN": token },
          windowsVerbatimArguments: true
        },
        async () => {
          this.$set(this.assetsFoldersExists, asset.key, await checkFolderExists(this.assetsPath + '\\' + asset.path));
        });
    },
    getAssetFromB2(asset) {
      log.log('getAssetFromB2', asset.path);
      let env = this.config.b2AssetsEnv;
      this.$emit('run-command', 'ShivaGetAsset.bat',
        [asset.path, this.assetsPath],
        {
          env,
          windowsVerbatimArguments: true
        },
        async () => {
          this.$set(this.assetsFoldersExists, asset.key, await checkFolderExists(this.assetsPath + '\\' + asset.path));
        });
    },
    async calculateSize(data) {
      log.log('calculateSize', data.item.path);
      // reccursive list all items in the folder and calculate the size
      let size = await calculateFolderSize(storage.refFromURL("gs://bs-assets/" + data.item.path));
      log.log('calculateSize', data.item.path, size);
      db.collection('ShivaGetAssets').doc(data.item.key).set({ size }, { merge: true });
    },
    setAssetDate() {
      log.log('setAssetDate');
      let count = 0;
      // Sets date if there is no date set.
      this.assetsInfo_asArray.forEach((item) => {
        if (!item.date) {
          count += 1;
          db.collection('ShivaGetAssets').doc(item.id).set({ date: serverTimestamp() }, { merge: true });
        }
      });
      log.log('Assets modified:', count);
    },
    setAssetsPath() {
      log.log('setAssetsPath');
      let count = 0;
      // Sets date if there is no date set.
      this.assetsInfo_asArray.forEach((item) => {
        if (!item.path) {
          count += 1;
          let ass = this.assetsOnStorage.find((v) => v.key == item.id);
          if (!ass?.name) {
            log.error("asset not found for", item.id);
            return;
          }
          let path = ass?.name;
          log.log(path);
          db.collection('ShivaGetAssets').doc(item.id).set({ path }, { merge: true });
        }
      });
      log.log('Assets modified:', count);
    },
    async scanLocalAssets() {
      log.log("scanLocalAssets");
      if (!this.assetsPath) {
        log.log("cannot scan, no local asset path set");
        return;
      }
      this.assetsInfo_asArray.forEach(async (item) => {
        // log.log("checking", item.path, item.id);
        this.$set(this.assetsFoldersExists, item.id, await checkFolderExists(this.assetsPath + '\\' + item.path));
      });
      log.log("assetsFoldersExists", this.assetsFoldersExists);
    },
    async loadAssetsFromGCS() {
      log.log('loadAssetsFromGCS');
      // list assets from google cloud storage
      this.assetsOnStorage = [];
      // list only folders with 2 levels deep from /ue folder and the size of the folder
      let res = await storage.refFromURL("gs://bs-assets/ue").listAll();
      await res.prefixes.forEach(async (folderRef) => {
        let res = await folderRef.listAll();
        res.prefixes.forEach(async (itemRef) => {
          let name = itemRef.fullPath;
          let key = itemRef.fullPath.replace(/\//g, '_');
          this.assetsOnStorage.push({
            name: name,
            path: name,
            key: key,
          });
          if (this.assetsPath)
            this.$set(this.assetsFoldersExists, key, await checkFolderExists(this.assetsPath + '\\' + name));
        });
      });
    },
  },
};
</script>

<style scoped>
.half-width {
  max-width: 50vw;
}
</style>